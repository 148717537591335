import Page from 'components/layout/Page';
import CircularToManfredHero from './sections/hero/CircularToManfredHero';
import ManfredToCircularCases from './sections/services/ManfredToCircularCases';

const CircularToManfred: React.FC = () => {
  return (
    <Page pageVariant="midnight_light" hasScrollTop>
      <CircularToManfredHero />
      <ManfredToCircularCases />
    </Page>
  );
};

export default CircularToManfred;
