import { ButtonLink } from '@asgard/ui-library';
import { faArrowRight, faSignIn } from '@fortawesome/pro-solid-svg-icons';
import HtmlTrans from 'components/shared/HtmlTrans';
import { Heading2 } from 'components/shared/Typography.styled';
import { useI18n } from 'hooks/useI18n';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import {
  StyledContainer,
  StyledHeader,
  StyledItem,
  StyledItemCase,
  StyledItemContent,
  StyledItemTitle,
  StyledList,
  StyledWrapper
} from './ManfredToCircularCases.styled';

const ManfredToCircularCases: React.FC = () => {
  const { t } = useI18n('circular-to-manfred');
  const { locale } = useRouter();

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledHeader>
          <Heading2 editorial>{t('circular-to-manfred.cases.title')}</Heading2>
          <HtmlTrans
            ns={'circular-to-manfred'}
            i18nKey={'circular-to-manfred.cases.desc'}
          />
        </StyledHeader>

        <StyledList>
          <StyledItem>
            <StyledItemContent>
              <StyledItemTitle>
                {t('circular-to-manfred.cases.item-1.title')}
              </StyledItemTitle>
              <HtmlTrans
                ns="circular-to-manfred"
                i18nKey={'circular-to-manfred.cases.item-1.desc'}
              />
              <StyledItemCase>
                <div>
                  <Link href={getAppUrl(APP_URLS.companies, locale)} passHref>
                    <ButtonLink
                      label={t('circular-to-manfred.cases.item-1.cta')}
                      schema="brand2"
                      variant="hard"
                      endIcon={faArrowRight}
                    />
                  </Link>
                </div>
              </StyledItemCase>
            </StyledItemContent>
          </StyledItem>

          <StyledItem>
            <StyledItemContent>
              <StyledItemTitle>
                {t('circular-to-manfred.cases.item-2.title')}
              </StyledItemTitle>
              <HtmlTrans
                ns="circular-to-manfred"
                i18nKey={'circular-to-manfred.cases.item-2.desc'}
              />
              <StyledItemCase>
                <div>
                  <Link href={getAppUrl(APP_URLS.join, locale)} passHref>
                    <ButtonLink
                      label={t('circular-to-manfred.cases.item-2.cta')}
                      schema="brand2"
                      variant="hard"
                      endIcon={faSignIn}
                    />
                  </Link>
                </div>
              </StyledItemCase>
            </StyledItemContent>
          </StyledItem>
        </StyledList>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default ManfredToCircularCases;
