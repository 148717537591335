import CircularToManfred from 'components/marketingSite/circular-to-manfred/CircularToManfred';
import HeadMetadata from 'components/shared/HeadMetadata';
import { useI18n } from 'hooks/useI18n';
import { usePageTrack } from 'hooks/usePageTrack';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { APP_URLS } from 'utils/app-urls';
import { config } from 'utils/config';

const ConsultancyPage: React.FC = () => {
  const { t } = useI18n('circular-to-manfred');

  usePageTrack('Landing - Page Loaded');

  return (
    <>
      <HeadMetadata
        title={t('circular-to-manfred.page_title')}
        url={APP_URLS.circularToManfred}
        desc={t('circular-to-manfred.page_description')}
      />
      <CircularToManfred />
    </>
  );
};

export default ConsultancyPage;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || '', [
        'common',
        'topbar',
        'footer',
        'circular-to-manfred'
      ])),
      baseUrl: config.appUrl
    }
  };
};
