import HtmlTrans from 'components/shared/HtmlTrans';
import { Heading1 } from 'components/shared/Typography.styled';
import { useI18n } from 'hooks/useI18n';
import {
  StyledContainer,
  StyledHero,
  StyledPreTitle,
  StyledWrapper
} from './CircularToManfredHero.styled';

const CircularToManfredHero: React.FC = () => {
  const { t } = useI18n('circular-to-manfred');

  return (
    <StyledHero>
      <StyledWrapper>
        <StyledContainer>
          <div>
            <StyledPreTitle>
              {t('circular-to-manfred.hero.pre-title')}
            </StyledPreTitle>
            <Heading1>
              <HtmlTrans
                ns={'circular-to-manfred'}
                i18nKey={'circular-to-manfred.hero.title'}
                components={{ br: <br /> }}
              />
            </Heading1>
            <HtmlTrans
              ns={'circular-to-manfred'}
              i18nKey={'circular-to-manfred.hero.desc'}
            />
          </div>
        </StyledContainer>
      </StyledWrapper>
    </StyledHero>
  );
};

export default CircularToManfredHero;
