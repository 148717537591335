import { Heading2 } from 'components/shared/Typography.styled';
import styled from 'styled-components';
import {
  color,
  container,
  fontSize,
  fontWeight,
  radius,
  screen,
  shadow,
  spacing
} from 'theme';

export const StyledWrapper = styled.div`
  background-color: ${color('midnight_100')};

  .logos {
    margin-bottom: 5rem;
    img {
      width: 35%;

      &.manfred {
        margin-bottom: -2rem;
      }
    }

    svg {
      margin-bottom: -1rem;
      color: #1c1872;
    }

    .down {
      display: block;
    }

    .right {
      display: none;
    }

    @media (min-width: ${screen('md')}) {
      .down {
        display: none;
      }

      .right {
        display: block;
      }
    }
  }
`;

export const StyledContainer = styled.div`
  max-width: ${container('xl')};
  padding: ${spacing('xl4')} ${spacing('md')};
  margin: auto;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('lg')};
  font-size: ${fontSize('md')};
  line-height: 1.5;
  margin: 0 auto ${spacing('md')};
  max-width: 58rem;

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('lg')};
    line-height: 1.6;
    margin-bottom: ${spacing('xl2')};
    text-align: center;
  }

  @media (max-width: ${screen('lgMax')}) {
    ${Heading2}::before {
      transform: unset;
      left: 0;
      height: ${spacing('xs2')};
    }
  }

  p:first-of-type {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacing('xl')};

  @media (min-width: ${screen('lg')}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledItemImage = styled.img`
  height: 20rem;
  width: 100%;
  object-fit: cover;
  border-radius: ${radius('lg')} ${radius('lg')} 0 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - ${spacing('lg')}));
`;

export const StyledItemContent = styled.div`
  padding: 0 ${spacing('mdlg')} ${spacing('mdlg')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
`;

export const StyledItemTitle = styled.h3`
  display: flex;
  flex-direction: column;
  gap: ${spacing('md')};
  font-size: ${fontSize('xl')};
  line-height: 1.14;
  font-weight: ${fontWeight('black')};
  margin-top: 0;
  margin-bottom: ${spacing('md')};

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl2')};
  }

  &::before {
    content: '';
    width: ${spacing('xl')};
    height: ${spacing('xs2')};
    background-color: ${color('cyan_700')};
  }
`;

export const StyledItemTag = styled.span`
  color: ${color('text_subtle')};
  font-size: ${fontSize('sm')};
  line-height: 1.14;
  font-weight: ${fontWeight('black')};
  text-transform: uppercase;
  letter-spacing: 0.25em;
  padding: ${spacing('xs')};
  margin-bottom: ${spacing('md')};
  background-color: ${color('midnight_150')};
  border-radius: ${radius('sm')};
`;

export const StyledItemCase = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${spacing('mdlg')};
  color: ${color('text_subtle')};
  line-height: 1.2;
  font-weight: ${fontWeight('black')};
  padding-top: ${spacing('sm')};
  border-top: 1px solid ${color('midnight_200')};

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('lg')};
  }

  img {
    display: none;
    height: 7.5rem;
    aspect-ratio: 1 / 1;
    object-fit: cover;

    @media (min-width: ${screen('md')}) {
      display: block;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: ${spacing('xs')};
  }
`;

export const StyledItemCaseTitle = styled.div`
  color: ${color('text_subtle')};
  font-size: ${fontSize('xs2')};
  line-height: 1.2;
  font-weight: ${fontWeight('black')};
  text-transform: uppercase;
`;

export const StyledItem = styled.div`
  background-color: ${color('white')};
  border-radius: ${radius('lg')};
  box-shadow: ${shadow('sm')};
  max-width: 40rem;
  margin: auto;
  padding-top: 1rem;

  a {
    width: 100%;
  }

  ul {
    padding-left: 0 !important;
    margin: ${spacing('mdlg')} 0;
    display: flex;
    flex-direction: column;
    gap: ${spacing('mdlg')};
  }

  li {
    margin: 0;
    display: flex;
    gap: ${spacing('sm')};
    line-height: 1.5;

    &::before {
      content: '';
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzE4ODIxXzg5NTQpIj4KPHBhdGggZD0iTTIwIDEwQzIwIDE1LjUyMjkgMTUuNTIyOSAyMCAxMCAyMEM0LjQ3NzE0IDIwIDAgMTUuNTIyOSAwIDEwQzAgNC40NzcxNCA0LjQ3NzE0IDAgMTAgMEMxNS41MjI5IDAgMjAgNC40NzcxNCAyMCAxMFpNOC44NDMzMSAxNS4yOTQ5TDE2LjI2MjcgNy44NzU1NkMxNi41MTQ2IDcuNjIzNjMgMTYuNTE0NiA3LjIxNTEyIDE2LjI2MjcgNi45NjMxOUwxNS4zNTAzIDYuMDUwODFDMTUuMDk4MyA1Ljc5ODgzIDE0LjY4OTggNS43OTg4MyAxNC40Mzc5IDYuMDUwODFMOC4zODcxIDEyLjEwMTVMNS41NjIxNCA5LjI3NjU3QzUuMzEwMiA5LjAyNDY0IDQuOTAxNjkgOS4wMjQ2NCA0LjY0OTcyIDkuMjc2NTdMMy43MzczNCAxMC4xODlDMy40ODU0IDEwLjQ0MDkgMy40ODU0IDEwLjg0OTQgMy43MzczNCAxMS4xMDEzTDcuOTMwODkgMTUuMjk0OUM4LjE4Mjg2IDE1LjU0NjkgOC41OTEzMyAxNS41NDY5IDguODQzMzEgMTUuMjk0OVoiIGZpbGw9IiM3OEQ1NDkiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8xODgyMV84OTU0Ij4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=);
      width: ${spacing('mdlg')};
      height: ${spacing('mdlg')};
      flex-shrink: 0;
      margin-top: 1px;
    }
  }

  @media (min-width: ${screen('lg')}) {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;
