import styled from 'styled-components';
import { color, container, fontSize, fontWeight, screen, spacing } from 'theme';
import { getPublicPath } from 'utils/paths';

export const StyledHero = styled.div`
  background-image: ${() =>
    `url('${getPublicPath('/images/consultancy/hero-bg-sm.svg')}')`};
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  padding: ${spacing('xl4')} ${spacing('md')} ${spacing('xl6')} ${spacing('md')};
  margin-bottom: ${spacing('s')};
  text-align: center;

  @media (min-width: ${screen('lg')}) {
    background-image: ${() =>
      `url('${getPublicPath('/images/consultancy/hero-bg-lg.svg')}')`};
  }
`;

export const StyledWrapper = styled.div`
  max-width: ${container('xl')};
  margin: auto;
`;

export const StyledContainer = styled.div`
  color: ${color('text_white')};
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${screen('md')}) {
    gap: ${spacing('xl')};
  }

  @media (min-width: ${screen('lg')}) {
    flex-direction: row;
    align-items: unset;
    font-size: ${fontSize('lg')};
    line-height: 1.6;
    gap: ${spacing('xl3')};
  }

  img {
    max-width: 80%;
    margin-bottom: -${spacing('xl4')};

    @media (min-width: ${screen('md')}) {
      max-width: 32rem;

      margin-bottom: -${spacing('xl5')};
    }
  }

  > div {
    max-width: 48rem;
    margin: 0 auto;
  }
`;

export const StyledPreTitle = styled.span`
  display: block;
  margin-bottom: ${spacing('md')};
  font-size: ${fontSize('md')};
  line-height: 1.2;
  font-weight: ${fontWeight('extraBold')};
  text-transform: uppercase;
  letter-spacing: 0.25em;

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('lg')};
    letter-spacing: 0.35em;
  }
`;
